import { createApp } from 'vue'
import myEvent from './index.js'
import App from './App.vue'
import ElementPlus from 'element-plus'
import locale from 'element-plus/dist/locale/zh-cn'
import 'element-plus/theme-chalk/index.css'
import 'normalize.css/normalize.css'
import '@/styles/index.less';
import SvgIcon from '@/components/SvgIcon/index.vue'
const req = require.context('@/icons/svg', false, /\.svg$/);
req.keys().map(req)
import router from "@/permission"
import store from "@/store/index"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import {getReq,postJson, posrForm, download} from "@/api/index"
import LyDrawer from "@/components/LyDrawer"
import uploadFile from "@/components/UploadFile"

const app = createApp(App)
app.config.globalProperties.$event = myEvent;
app.config.globalProperties.$get = getReq;
app.config.globalProperties.$post = postJson;
app.config.globalProperties.$posrForm = posrForm;
app.config.globalProperties.$download = download;
app.component(LyDrawer)
app.component("uploadFile",uploadFile)
app.use(router)
app.use(store)

// eslint-disable-next-line vue/multi-word-component-names
app.use(ElementPlus, {size: 'small',locale})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.component('svg-icon', SvgIcon);
app.mount('#app');

//静态地址
app.config.globalProperties.$ImgUrl = (src) => {
    return 'http://cdn.sws010.com'+ src;
}  


