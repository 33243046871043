export default {
    getToken(state) {
        return state.token
    },
    getUserInfo(state) {
        return state.userInfo
    },
    getTags(state) {
        return state.tags
    },
    getTagActive(state) {
        return state.tagActive
    },
    getBreadcrumbs(state) {
        return state.breadcrumbs
    },
    getOpenedKeys(state) {
        return state.openedKeys
    },
    getRole(state) {
        return state.role
    }
}