<template>
    <el-drawer v-model="show" :title="title" :size="width" v-bind="options">
        <span>Hi there!</span>
    </el-drawer>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
export default defineComponent({
    name: 'ly-drawer',
    props: {
        title: {
            type: String,
            default: ""
        },
        width: {
            type: [String, Number],
            default: 400
        },
        options: {
            type: Object,
            default: () => {}
        },
        visible: {
           type: Boolean,
           default: false 
        }
    },
    setup(props, ctx) {
        let show = ref(props.visible);
        function changeShow(val) {
            ctx.emit("update:visible",val)
        }
        watch(show, (val) => {
            changeShow(val);
        })
        return {
            show
        }
    }
})
</script>

<style lang="less" scoped>
</style>