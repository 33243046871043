import LS from 'cz-storage'
export default {
    setToken: (state, log) => {
        state.token = log
        LS.put('token', log, 1 / 8);
    },
    setUserInfo: (state, log) => {
        state.userInfo = log
        LS.put('userInfo', log, 1 / 8);
    },
    setTags: (state, log) => {
        let arr = JSON.parse(JSON.stringify(state.tags));
        let n = arr.findIndex(ele => {
            return ele.path === log.path
        })
        if(n === -1) {
            arr.push(log)
        }
        state.tags = arr;
        LS.put('tags', arr, 1 / 8);
    },
    setTagActive: (state, log) => {
        state.tagActive = log;
        LS.put('tagActive', log, 1 / 8);
    },
    setBreadcrumbs: (state, log) => {
        state.breadcrumbs = log;
    },
    setOpenedKeys: (state, log) => {
        const arr = state.openedKeys;
        if (Array.isArray(arr)) {
            const n = arr.findIndex(ele => ele === log);
            if (n === -1) {
                arr.push(log);
            }
            LS.put('openedKeys', arr, 1 / 8);
        }
    },
    delOpenedKeys: (state, log) => {
        const arr = state.openedKeys;
        if (Array.isArray(arr)) {
            const n = arr.findIndex(ele => ele === log);
            if (n != -1) {
                arr.splice(n, 1);
            }
            LS.put('openedKeys', arr, 1 / 8);
        }
    },
    setRole: (state, log) => {
        const arr = state.role;
        if (Array.isArray(arr)) {
            const n = arr.findIndex(ele => ele === log);
            if (n === -1) {
                arr.push(log);
            }
            LS.put('role', arr, 1 / 8);
        }
    },
}