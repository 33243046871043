import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
	{
		path: "/",
		name: "Layout",
		component: () => import("@/components/Layout/index.vue"),
		redirect: '/questManage',
		meta: {
			title: '首页',
			keepAlive: true
		},
		children: [
			{
				path: '/homePage',
				name: "HomePage",
				component: () => import("@/views/HomePage/index.vue"),
				meta: {
					title: '首页',
					keepAlive: true
				}
			},
			{
				path: '/questManage',
				name: "QuestManage",
				component: () => import("@/views/QuestManage/index.vue"),
				redirect: '/questManage/questList',
				meta: {
					title: '任务管理',
					keepAlive: true
				},
				children: [
					{
						path: "/questManage/questList",
						name: "QuestList",
						component: () => import("@/views/QuestManage/_questList/index.vue"),
						meta: {
							title: '任务列表',
							keepAlive: true
						},
					},
					{
						path: "/questManage/infoCollect",
						name: "InfoCollect",
						component: () => import("@/views/QuestManage/_infoCollect/index.vue"),
						meta: {
							title: '线索收集',
							keepAlive: true
						},
					},
					{
						path: "/questManage/questRelease",
						name: "QuestRelease",
						component: () => import("@/views/QuestManage/_questRelease/index.vue"),
						meta: {
							title: '发布任务',
						},
						hidden: true
					},
					{
						path: "/questManage/questDetail",
						name: "QuestDetail",
						component: () => import("@/views/QuestManage/_questDetail/index.vue"),
						meta: {
							title: '查看任务',
						},
						hidden: true
					},
					{
						path: "/questManage/transformResult",
						name: "TransformResult",
						component: () => import("@/views/QuestManage/_transformResult/index.vue"),
						meta: {
							title: '传播效果',
						},
						hidden: true
					},
					{
						path: "/questManage/transformResultDetail",
						name: "TransformResultDetail",
						component: () => import("@/views/QuestManage/_transformResultDetail/index.vue"),
						meta: {
							title: '传播效果详情',
						},
						hidden: true
					},
				]
			},
			{
				path: '/actRelease',
				name: "ActRelease",
				component: () => import("@/views/StarwayInfo/_actRelease/index.vue"),
				meta: {
					title: '发布动态',
				},
				hidden: true
			},
			{
				path: '/actDetail',
				name: "ActDetail",
				component: () => import("@/views/StarwayInfo/_actDetail/index.vue"),
				meta: {
					title: '动态详情',
				},
				hidden: true
			},
			{
				path: '/actEdit',
				name: "ActEdit",
				component: () => import("@/views/StarwayInfo/_actEdit/index.vue"),
				meta: {
					title: '编辑动态',
				},
				hidden: true
			},
			{
				path: '/matManage',
				name: "MatManage",
				component: () => import("@/views/MatManage/index.vue"),
				redirect: '/matManage/matList',
				meta: {
					title: '素材管理',
				},
				children: [
					{
						path: "/matManage/matList",
						name: "MatList",
						component: () => import("@/views/MatManage/_matList/index.vue"),
						meta: {
							title: '素材列表',
						},
					},
					{
						path: "/matManage/setManage",
						name: "SetManage",
						component: () => import("@/views/MatManage/_setManage/index.vue"),
						meta: {
							title: '分组管理',
						},
					},
					{
						path: "/matManage/matUpload",
						name: "MatUpload",
						component: () => import("@/views/MatManage/_matUpload/index.vue"),
						meta: {
							title: '上传素材',
						},
						hidden: true
					},
					{
						path: "/matManage/matEdit",
						name: "MatEdit",
						component: () => import("@/views/MatManage/_matEdit/index.vue"),
						meta: {
							title: '编辑素材',
						},
						hidden: true
					},
				]
			},
			{
				path: '/reviewManage',
				name: "ReviewManage",
				component: () => import("@/views/ReviewManage/index.vue"),
				redirect: '/reviewManage/transEffect',
				meta: {
					title: '审核管理',
				},
				children: [
					{
						path: "/reviewManage/transEffect",
						name: "TransEffect",
						component: () => import("@/views/ReviewManage/_transEffect/index.vue"),
						meta: {
							title: '传播效果审核',
						},
					},
					{
						path: "/reviewManage/accountReview",
						name: "AccountReview",
						component: () => import("@/views/ReviewManage/_accountReview/index.vue"),
						meta: {
							title: '关联账号审核',
						},
					},
				]
			},
			{
				path: '/basicTutorial',
				name: "BasicTutorial",
				component: () => import("@/views/BasicTutorial/index.vue"),
				meta: {
					title: '通用教程',
				},
			},
			{
				path: '/memberManage',
				name: "MemberManage",
				redirect: '/memberManage/memberList',
				component: () => import("@/views/MemberManage/index.vue"),
				meta: {
					title: '成员管理',
				},
				children: [
					{
						path: "/memberManage/memberList",
						name: "MemberList",
						component: () => import("@/views/MemberManage/_memberList/index.vue"),
						meta: {
							title: '员工列表',
						},
					},
					{
						path: "/memberManage/levelManage",
						name: "LevelManage",
						component: () => import("@/views/MemberManage/_levelManage/index.vue"),
						meta: {
							title: '级别管理',
						},
					},
					{
						path: "/memberManage/pointManage",
						name: "PointManage",
						component: () => import("@/views/MemberManage/_pointManage/index.vue"),
						meta: {
							title: '积分管理',
						},
					},
				]
			},
			{
				path: '/basicSetting',
				name: "BasicSetting",
				redirect: '/basicSetting/dicSetting',
				component: () => import("@/views/BasicSetting/index.vue"),
				meta: {
					title: '基础配置',
				},
				children: [
					{
						path: "/basicSetting/dicSetting",
						name: "DicSetting",
						component: () => import("@/views/BasicSetting/_dicSetting/index.vue"),
						meta: {
							title: '字典配置',
						},
					},
					{
						path: "/basicSetting/rewardRule",
						name: "RewardRule",
						component: () => import("@/views/BasicSetting/_rewardRule/index.vue"),
						meta: {
							title: '奖励规则',
						},
					},
					{
						path: "/basicSetting/transformValue",
						name: "TransformValue",
						component: () => import("@/views/BasicSetting/_transformValue/index.vue"),
						meta: {
							title: '传播值规则',
						},
					},
					{
						path: "/basicSetting/addReward",
						name: "AddReward",
						component: () => import("@/views/BasicSetting/_addReward/index.vue"),
						meta: {
							title: '添加奖励规则',
						},
						hidden: true
					},
				]
			},
			{
				path: '/systemManage',
				name: "SystemManage",
				redirect: '/systemManage/accountManage',
				component: () => import("@/views/SystemManage/index.vue"),
				meta: {
					title: '系统管理',
				},
				children: [
					{
						path: "/systemManage/accountManage",
						name: "AccountManage",
						component: () => import("@/views/SystemManage/_accountManage/index.vue"),
						meta: {
							title: '账号管理',
						},
					},
					{
						path: "/systemManage/charManage",
						name: "CharManage",
						component: () => import("@/views/SystemManage/_charManage/index.vue"),
						meta: {
							title: '角色管理',
						},
					},
				]
			},
		]
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import("@/views/Login/index.vue")
	}
	
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;