<template>
  <router-view></router-view>
</template>

<script>
import { defineComponent, ref, nextTick, provide } from 'vue';
export default defineComponent({
  name: 'App',
  setup() {
    // 局部组件刷新
    const isRouterAlive = ref(true);
    const reload = () => {
      isRouterAlive.value = false;
      nextTick(() => {
        isRouterAlive.value = true;
      });
    };
    provide("reload", reload);
    return {
      isRouterAlive,
    };
  }
})
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
  color: #666A6D;
  font-size: 62.5%;
  touch-action: none;
}

#app {
  position: relative;
  width: 100%;
  min-width: 1400px;
  height: 100%;
  min-height: 768px;
}
</style>
