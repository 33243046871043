export default {
    setToken: ({ commit }, log) => {
        commit('setToken', log);
    },
    setUserInfo: ({ commit }, log) => {
        commit('setUserInfo', log);
    },
    setTags: ({ commit }, log) => {
        commit('setTags', log);
    },
    setTagActive: ({ commit }, log) => {
        commit('setTagActive', log);
    },
    setBreadcrumbs: ({ commit }, log) => {
        commit('setBreadcrumbs', log);
    },
    setOpenedKeys: ({ commit }, log) => {
        commit('setOpenedKeys', log);
    },
    delOpenedKeys:  ({ commit }, log) => {
        commit('delOpenedKeys', log);
    },
    setRole:  ({ commit }, log) => {
        commit('setRole', log);
    },
}