<template>
    <div>
        <div>
            <el-upload class="upload-demo" :action="action" :data="{compress: isCompress}" :headers="headers" v-bind="config" :on-success="handlerSuccess" :on-error="handlerError" :on-remove="handlerRemove">
                <slot></slot>
            </el-upload>
        </div>
        <div class="upload-text">
            支持扩展名: {{config.accept}} 
            <div>{{cover}}</div>
        </div>
    </div>
</template>

<script>
// video: '.mp4, .ogv, .ogg, .webm',
// audio: '.wav, .mp3, .ogg, .acc, .webm, .amr',
// file: '.doc,.docx,.xlsx,.xls,.pdf',
// excel: '.xlsx,.xls'
// img: '.jpg, .jpeg, .png, .gif'

/* 
    <upload-file @change="handlerCertificate" :config="{ limit: 1, accept: '.jpg, .jpeg, .png, .gif'}">
        <img :src="form.certificatePhoto" v-if="type === 'edit'" />
        <i class="el-icon-camera-solid" v-if="type === 'add'"></i>
    </upload-file>
*/
import { defineComponent } from 'vue'
export default defineComponent({
    name: "UploadFile",
    props: {
        config: {
            type: Object,
            default: () => {},
        },
        msg: {
            type: String,
            default: "",
        },
        event: {
            type: Object,
            default: () => {},
        },
        cover: {
            type: String
        },
        isCompress: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const action = process.env.VUE_APP_URL + "util/upload"; //地址
        const headers = {
            
        };
        const filePath = '';
        return {
            action, headers, filePath
        }
    },
    methods: {
        // 上传成功
        handlerSuccess(response, file, fileList) {
            this.fileList = fileList || [];
            const data = response.data;
            this.$emit("change", data)
        },
        // 上传失败
        handlerError() {}, 
        // 删除文件的钩子
        handlerRemove(file, fileList) {
             this.fileList = fileList || [];
             this.$emit("change", this.fileList)
        }
    },
})
</script>

<style lang="less" scoped>
.upload-text {
    color: #BBB;
}
</style>